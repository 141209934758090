<template>
  <div>
    <b-row class="p-0">
      <b-col cols="12">
        <b-card>
          <b-card-body class="p-0">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  Personal Info
                </h5>
                <small class="text-muted">
                  Enter Your Account Details.
                </small>
              </b-col>
              <b-col md="4">
                <b-form-group label="First Name" label-for="first_name">
                  <b-form-input
                    v-model="data.first_name"
                    id="name"
                    placeholder="First Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Middle Name" label-for="middle_name">
                  <b-form-input
                    v-model="data.middle_name"
                    id="name"
                    placeholder="Middle Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Last Name" label-for="last_name">
                  <b-form-input
                    v-model="data.last_name"
                    id="name"
                    placeholder="Last Name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Address" label-for="address_text">
                  <b-form-textarea
                    v-model="data.address"
                    id="address_text"
                    placeholder="Address"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Country" label-for="country">
                  <b-form-select 
                    v-model="data.country"
                    id="country"
                    @change="getState()"
                    :options="countryOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="State" label-for="state">
                  <b-form-select 
                    v-model="data.state"
                    id="state"
                    @change="getCity()"
                    :options="stateOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="City" label-for="city">
                  <b-form-select
                    v-model="data.city"
                    id="city" 
                    :options="cityOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Email" label-for="email_text">
                  <b-form-input
                    v-model="data.email"
                    id="email_text"
                    type="email"
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Phone Number" label-for="phoneNumber">
                  <b-input-group :prepend="data.phone_code">
                    <b-form-input 
                      v-model="data.phone_number"
                      id="phone"
                      placeholder="Phone"/>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Gender" label-for="gender">
                  <b-form-select 
                    v-model="data.gender"
                    id="gender"
                    :options="genderOptions"/>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date of Birth" label-for="dateBirth">
                  <b-form-input
                    v-model="data.dob"
                    id="dateBirth"
                    type="date"
                    placeholder="Date of Birth"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Username"
                  label-for="username"
                >
                  <b-form-input
                    v-model="data.username"
                    id="username"
                    placeholder="Username"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="my-3">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  Additional Info
                </h5>
                <small class="text-muted">
                  Enter Your Additional Info.
                </small>
              </b-col>
              <b-col md="4">
                <b-form-group label="Bussines Category" label-for="work">
                  <b-form-select 
                    id="work"
                    v-model="data.work"
                    :options="workOptions" />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Occupation" label-for="occupation">
                  <b-form-select 
                    id="occupation"
                    v-model="data.occupation"
                    :options="occupationOptions" />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Education" label-for="education">
                  <b-form-select 
                    id="education"
                    v-model="data.education"
                    :options="educationOptions" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Provider" label-for="provider">
                  <b-form-select 
                    id="provider"
                    v-model="data.provider"
                    :options="providerOptions" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Phone Type" label-for="phoneType">
                  <b-form-select 
                    id="phoneType"
                    v-model="data.phone_type"
                    :options="phoneTypeOptions" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Text Savvy"
                  label-for="textSavvy"
                >
                  <b-form-checkbox
                    v-model="data.text_savvy"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-2">
                <b-button variant="primary" @click="submit">Edit</b-button>
                <b-button variant="secondary" class="ml-1" @click="gotoMember">Cancel</b-button>
              </b-col>
            </b-row>            
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow, BCol,
    BCard, BCardBody,
    BFormGroup, BFormInput, BInputGroup, BFormTextarea, BFormSelect, BFormCheckbox,
    BButton
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      BRow, BCol,
      BCard, BCardBody,
      BFormGroup, BFormInput, BInputGroup, BFormTextarea, BFormSelect, BFormCheckbox,
      BButton
    },
		directives: {
			Ripple,
		},
    data() {
      return {
        data: {
          first_name: null,
          middle_name: null,
          last_nme: null,
          email: null,
          address: null,
          country: null,
          state: null,
          city: null,
          phone_code: "",
          phone_number: null,
          gender: null,
          dob: null,
          work: null,
          occupation: null,
          education: null,
          provider: null,
          phone_type: null,
          username: null,
          text_savvy: null
        },
        genderOptions: [
          { value: 'man', text: 'Man' },
          { value: 'woman', text: 'Woman' }
        ],
        workOptions: [
          { value: 'crafting', text: 'Crafting' },
          { value: 'fashion', text: 'Fashion' },
          { value: 'culinary', text: 'Culinary' },
          { value: 'merchant', text: 'Merchant' },
          { value: 'teacher', text: 'Teacher' },
          { value: 'agricultural', text: 'Agricultural' },
          { value: 'banking', text: 'Banking' },
          { value: 'others', text: 'Others' }
        ],
        occupationOptions: [
          { value: 'chef', text: 'Chef' },
          { value: 'cashier', text: 'Cashier' },
          { value: 'barber', text: 'Barber' },
          { value: 'dentist', text: 'Dentist' },
          { value: 'dentist', text: 'Dentist' },
          { value: 'driver', text: 'Driver' },
          { value: 'teacher', text: 'Teacher' },
          { value: 'tailor', text: 'Tailor' },
          { value: 'mechanic', text: 'Mechanic' },
          { value: 'engineer', text: 'Engineer' },
          { value: 'salesman', text: 'Salesman' },
          { value: 'porter', text: 'Porter' },
          { value: 'doctor', text: 'Doctor' },
          { value: 'employee', text: 'Employee' },
          { value: 'businesman', text: 'Businesman' },
          { value: 'others', text: 'Others' },
        ],
        educationOptions: [
          { value: 'sd', text: 'SD' },
          { value: 'smp', text: 'SMP' },
          { value: 'sma/smk', text: 'SMA/SMK' },
          { value: 'diploma', text: 'Diploma' },
          { value: 'sarjana', text: 'Sarjana' },
          { value: 'pasca sarjana', text: 'Pasca Sarjana' },
        ],
        socioeconomicOptions: [
          { value: 'mikro', text: 'Mikro (< 300 Jt)' },
          { value: 'kecil', text: 'Kecil (300 Jt s/d 2.5 M)' },
          { value: 'menengah', text: 'Kecil (2.5 M s/d 50 M)' },
          { value: 'besar', text: 'Besar (> 50 M)' },
        ],
        providerOptions: [
          { value: 'xl', text: 'XL' },
          { value: 'indosat', text: 'Indosat' },
          { value: 'telkomsel', text: 'Telkomsel' },
          { value: 'byu', text: 'By.U' },
          { value: 'other', text: 'Other' },
        ],
        phoneTypeOptions: [
          { value: 'samsung', text: 'Samsung' },
          { value: 'iphone', text: 'Iphone' },
          { value: 'oppo', text: 'Oppo' },
          { value: 'other', text: 'Other' }
        ],
        countryData: [],
        countryOptions: [],
        stateOptions: [],
        cityOptions: []
      }
    },
		created() {
      this.checkAuth()
    },
    mounted() {
      this.getDetailData()
      this.getCountry()
    },
    methods: {
      getCountry() {
        this.$axios.get('all/csc/countries')
          .then(res => {
            const { data } = res.data
            
            this.countryData = data
            data.forEach(item => {
              let country = {
                value: item.id,
                text: item.name,
              }

              this.countryOptions.push(country)
            })
          })
      },
      getState(id = null) {
        let country = null
        if(id) {
          country = parseInt(id)
        } else {
          country = this.data.country

          let countryFilter = this.countryData.filter(item => {
            return item.id == country
          })
          this.data.phone_code = countryFilter[0].phonecode
        }

        this.$axios.get(`all/csc/countries/${country}/states`)
          .then(res => {
            const { data } = res.data

            this.stateOptions = []
            this.cityOptions = []
            data.forEach(item => {
              let state = {
                value: item.id,
                text: item.name
              }

              this.stateOptions.push(state)
            })
          })
      },
      getCity(id = null) {
        let state = null
        if(id) {
          state = id
        } else {
          state = this.data.state
        }

        this.$axios.get(`all/csc/states/${state}/city`)
          .then(res => {
            const { data } = res.data

            this.cityOptions = []
            data.forEach(item => {
              let city = {
                value: item.id,
                text: item.name
              }

              this.cityOptions.push(city)
            })
          })
      },

      gotoMember() {
        this.$router.push({ path: `/user/member` })
      },
      async getDetailData() {
				let id = this.$route.params.id
				let cid = localStorage.getItem('client_id')
				
				let dataAPI = await this.$axios.get(`${cid}/noauth/member/${id}`)
				const data_member = dataAPI.data.data

        this.data.first_name = data_member.member_first_name
        this.data.middle_name = data_member.member_middle_name
        this.data.last_name = data_member.member_last_name
        this.data.address = data_member.member_address
        this.data.country = data_member.member_country
        this.data.state = data_member.member_state
        this.data.city = data_member.member_city

        if(data_member.member_country) {
          this.getState(data_member.member_country)
        }
        
        if(data_member.member_state) {
          this.getCity(data_member.member_state)
        }

        this.data.email = data_member.member_email
        this.data.phone_code = data_member.phone_code
        this.data.phone_number = data_member.member_phone
        this.data.gender = data_member.member_gender
        this.data.dob = data_member.member_dob
        this.data.username = data_member.user.username
        this.data.work = data_member.member_work
        this.data.occupation = data_member.member_occupation
        this.data.education = data_member.member_education
        this.data.provider = data_member.member_provider
        this.data.phone_type = data_member.member_phone_type

        if(data_member.member_text_savvy == 1) {
          this.data.text_savvy = true
        } else {
          this.data.text_savvy = false
        }
			},
      submit() {
        let cid = localStorage.getItem('client_id')
        let id = this.$route.params.id
        let data = this.data

        let formData = {
          first_name: data.first_name,
          middle_name: data.middle_name,
          last_name: data.last_name,
          gender: data.gender,
          dob: data.dob,
          provider: data.provider,
          phone_type: data.phone_type,
          work: data.work,
          occupation: data.occupation,
          education: data.education,
          savvy: data.text_savvy,
          email: data.email,
          phonecode: data.phone_code,
          contact_phone: data.phone_number,
          username: data.username,
          address: data.address,
          country: data.country,
          state: data.state,
          city: data.city
        }

        this.$axios.put(`${cid}/noauth/member/edit/${id}`, formData)
          .then(res => {
            // Success code
            this.$bvToast.toast('Success', {
              title: 'Edit data member',
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-right',
              autoHideDelay: 3000
            })

            setTimeout(() => {
              this.$router.push({ path: '/user/member' })
            }, 3000);
          })
          .catch(error => {
            this.$bvToast.toast('Error', {
              title: 'Edit data member',
              variant: 'danger',
              solid: true,
              toaster: 'b-toaster-bottom-right',
              autoHideDelay: 3000
            })
          })
      }
    }
  }
</script>
